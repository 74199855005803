import {Navigate, Route, Routes, useLocation, useParams} from 'react-router-dom'
import PageNavigation, { NavigationItem } from '../../../components/common/PageNavigation'
import DriverInfo from './SubPages/DriverInfo';
import './styles.sass'
import DriverDocs from './SubPages/DriverDocs';
import NotFound from '../../NotFound/NotFound';

const links: NavigationItem[] = [
  {
    type: 'link',
    to: '../info',
    text: "Haydovchi ma'lumotlari"
  },
  {
    type: 'link',
    to: '../docs',
    text: 'Haydovchi dokumentlari'
  },
];

const navComponent = <PageNavigation links={links} />

const DriversView = () => {
  const location = useLocation();
  const state = location.state || {}
  const {companyId} = useParams()
    console.log(state)
  return (
    <Routes>
      <Route path='info' element={ <DriverInfo driverName={state.name} navigationSlot={navComponent} /> } />
      <Route path='docs' element={ <DriverDocs driverName={state.name} navigationSlot={navComponent} /> } />
      <Route path='/' element={<Navigate to='info' />}  />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default DriversView