import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../common/Logo';
import UserAvatar from '../../../assets/temporary/user-avatar.png';
import {
	DashboardIcon,
	BoxIcon,
	BuildingIcon,
	TruckIcon,
	MapIcon,
	DocIcon,
	SettingsIcon,
	SideBarIcon
} from "../../../utils/icons";
import useModal from '../../../hooks/useModal';
import { Modal } from '../../common/Modal';
import { useTranslation } from 'react-i18next';
import './styles.sass';
import Cookies from "js-cookie";

interface SidebarProps {
	isCollapsed: boolean;
	onToggle: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isCollapsed, onToggle }) => {
	const navigate = useNavigate();
	const { openModal, isOpen, closeModal } = useModal();
	const { t } = useTranslation();

	const logout = () => {
		Cookies.remove('accessToken');
		navigate('/auth/sign-in'); // Переход на страницу логина
	};

	return (
		<div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
			<div className='sidebar-header'>
				<Logo type='full' viewStyle='white' width={isCollapsed ? '80px' : '150px'} height='100px'/>
			</div>
			<button className='toggle-button' onClick={onToggle}>
				<SideBarIcon/>
			</button>
			<div className='sidebar-navigation'>
				<div className='sidebar-navigation__item' onClick={openModal}>
					<span className="navigation__icon"><DashboardIcon/></span>
					{!isCollapsed && <div className="navigation__text">{t('dashboard')}</div>}
				</div>
				<div className='sidebar-navigation__item' onClick={() => navigate('companies')}>
					<span className="navigation__icon"><BuildingIcon/></span>
					{!isCollapsed && <div className="navigation__text">{t('companies')}</div>}
				</div>
				<div className='sidebar-navigation__item' onClick={openModal}>
					<span className="navigation__icon"><BoxIcon/></span>
					{!isCollapsed && <div className="navigation__text">{t('cargo')}</div>}
				</div>
				<div className='sidebar-navigation__item' onClick={() => navigate('maps')}>
					<span className="navigation__icon"><MapIcon/></span>
					{!isCollapsed && <div className="navigation__text">{t('maps')}</div>}
				</div>
				<div className='sidebar-navigation__item' onClick={openModal}>
					<span className="navigation__icon"><DocIcon/></span>
					{!isCollapsed && <div className="navigation__text">{t('documents')}</div>}
				</div>
				<div className='sidebar-navigation__item' onClick={openModal}>
					<span className="navigation__icon"><SettingsIcon/></span>
					{!isCollapsed && <div className="navigation__text">{t('settings')}</div>}
				</div>
			</div>

			<div className='sidebar-bottom'>
				{!isCollapsed && (
					<div className='user-information'>
						<div className='user-avatar'><img src={UserAvatar} alt='User avatar'/></div>
						<div className='user-contact'>
							<div className='user-contact-fullname'><span>Ahrorbek Mirzaev</span></div>
							<div className='user-contact-phone'><span>+998 (77) 353-88-88</span></div>
							<div style={{color:"red"}} onClick={logout}>{t('logout')}</div>
						</div>
					</div>
				)}
			</div>

			{isOpen && (
				<Modal title={t('sidebar:info')} titlePosition='center' onClose={closeModal}>
					<div className="modal-routes-info">{t('nav-msg')}</div>
				</Modal>
			)}
		</div>
	);
};

export default Sidebar;