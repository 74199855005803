import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CompanyInfo from './SubPages/CompanyInfo';
import CompanyDocs from './SubPages/CompanyDocs';
import PageNavigation, { NavigationItem } from '../../../components/common/PageNavigation';
import './styles.sass';
import CompanyDrivers from './SubPages/CompanyDrivers';
import CompanyTransports from './SubPages/CompanyTrucks';
import NotFound from '../../NotFound/NotFound';
import CompanyTrailers from './SubPages/CompanyTrailers';
import { Company } from "../../../types/company.types";
import { fetchCompanyById } from "../../../api/hooks/company/useSingleCompany";
import { useTranslation } from 'react-i18next';

const ViewCompanies = () => {
  const { companyID } = useParams<{ companyID: string }>(); // Capture companyID from the URL
  const [company, setCompany] = useState<Company | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  // Make API request to fetch company data
  useEffect(() => {
    const loadCompany = async () => {
      try {
        const data = await fetchCompanyById(companyID!);  // Fetch company data
        setCompany(data);
      } catch (error) {
        console.error('Error fetching company data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadCompany();
  }, [companyID]);

  if (loading) return <div>{t('loading')}</div>;  // Show loading message

  // Dynamically generate links with companyID
  const links: NavigationItem[] = [
    {
      type: 'link',
      to: `/dashboard/companies/view/${companyID}/info`,  // Absolute path to info page
      text: t('company_info'),
    },
    {
      type: 'link',
      to: `/dashboard/companies/view/${companyID}/docs`,  // Absolute path to docs page
      text: t('company_docs'),
    },
    {
      type: 'link',
      to: `/dashboard/companies/view/${companyID}/drivers`,  // Absolute path to drivers page
      text: t('company_drivers'),
    },
    {
      type: 'dropdown',
      text: t('company_trucks'),
      links: [
        {
          to: `/dashboard/companies/view/${companyID}/trucks`,  // Absolute path to trucks page
          text: t('company_trucks'),
        },
        {
          to: `/dashboard/companies/view/${companyID}/trailers`,  // Absolute path to trailers page
          text: t('company_trailers'),
        },
      ],
    },
  ];

  const navComponent = <PageNavigation links={links} />;

  return (
      <Routes>
        <Route path="info" element={<CompanyInfo navigationSlot={navComponent} company={company!} />} />
        <Route path="docs" element={<CompanyDocs navigationSlot={navComponent} />} />
        <Route path="drivers" element={<CompanyDrivers navigationSlot={navComponent} />} />
        <Route path="trucks" element={<CompanyTransports navigationSlot={navComponent} />} />
        <Route path="trailers" element={<CompanyTrailers navigationSlot={navComponent} />} />
        <Route path="/" element={<Navigate to="info" />} />  {/* Default redirect to info */}
        <Route path="*" element={<NotFound />} />
      </Routes>
  );
};

export default ViewCompanies;