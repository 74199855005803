import { ReactNode } from 'react'
import ContentWrapper from '../../../../../components/common/ContentWrapper'
import Button from '../../../../../components/common/Button'
import FlexWrapper from '../../../../../components/common/FlexWrapper'
import DataView from '../../../../../components/common/DataView'
import { PenIcon } from "../../../../../utils/icons";
import PageInfo from '../../../../../components/dashboard/PageInfo'
import { Company } from "../../../../../types/company.types";
import { useTranslation } from 'react-i18next';

interface CompanyInfoProps {
  navigationSlot: ReactNode;
  company: Company;
}

const CompanyInfo = ({ navigationSlot, company }: CompanyInfoProps) => {
  const { t } = useTranslation(); // Use translation hook

  return (
      <div className='page-companies-view'>
        <PageInfo
            title={company.name}
            backButton
            url='/dashboard/companies'
        />

        <FlexWrapper>
          {navigationSlot}

          <div className="page-content">
            <ContentWrapper>
              <div className="section-title">
                {t('company_info')}  {/* Translated title */}

                <Button variant='clear'>
                  <PenIcon />
                </Button>
              </div>

              <FlexWrapper>
                <DataView style={{ flex: 1 }} label={t('company_name')} value={company.name} />
                <DataView style={{ flex: 1 }} label={t('company_owner')} value={company.owner.name} />
                <DataView style={{ flex: 1 }} label={t('company_inn')} value={company.inn} />
              </FlexWrapper>

              <FlexWrapper>
                <DataView style={{ flex: 1 }} label={t('company_transport_count')} value={company.transportCount} />
                <DataView style={{ flex: 1 }} label={t('company_employee_count')} value={company.employeeCount} />
                <DataView style={{ flex: 1 }} label={t('company_founded')} value={company.founded} />
              </FlexWrapper>
            </ContentWrapper>

            <ContentWrapper style={{ marginTop: 24 }}>
              <h3 className="section-title">{t('bank_requisites')}</h3> {/* Translated section title */}

              {company?.requisites?.map((bank, index) => (
                  <ContentWrapper key={index} background='ltblue'>
                    <FlexWrapper>
                      <DataView style={{ flex: 2 }} label={t('bank_name')} value={bank.bank} />
                      <DataView style={{ flex: 2 }} label={t('bank_account_number')} value={bank.accountNumber} />
                      <DataView style={{ flex: 1 }} label={t('bank_mfo')} value={bank.mfo} />
                      <DataView style={{ flex: 1 }} label={t('bank_currency')} value={bank.currency} />
                      <Button variant='clear'>
                        <PenIcon />
                      </Button>
                    </FlexWrapper>
                  </ContentWrapper>
              ))}
            </ContentWrapper>
          </div>
        </FlexWrapper>
      </div>
  )
}

export default CompanyInfo;