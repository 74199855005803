import Cookie from 'js-cookie';
import {jwtDecode} from 'jwt-decode';

export const getUserIdFromToken = (): string | null => {
    const token = Cookie.get('accessToken');
    if (token) {
        try {
            const decodedToken: any = jwtDecode(token);
            return decodedToken.id || null;
        } catch (error) {
            console.error("Invalid token:", error);
            return null;
        }
    }
    return null;
};