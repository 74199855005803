import { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OneIdButton from '../../../components/authorization/OneIdButton';
import Button from '../../../components/common/Button';
import Input from '../../../components/common/Input';
import { useLogin } from '../../../api/auth/mutation/login'; // Импортируем мутацию
import './styles.sass';

const defaultInputsValues = {
  phone: '',
  password: ''
};

const SignIn = () => {
  const navigate = useNavigate();

  const [formFieldsValue, setFormFieldsValue] = useState(defaultInputsValues);
  const [formError, setFormError] = useState('');
  const [authError, setAuthError] = useState('');

  const { mutate: login, isLoading: authPending, error: authMutationError } = useLogin() as any;

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormFieldsValue({
      ...formFieldsValue,
      [name]: value
    });

    setAuthError('');
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formFieldsValue.password && formFieldsValue.phone) {
      //  мутация  логина
      login({ phone: formFieldsValue.phone, password: formFieldsValue.password });
    } else {
      setFormError('Заполните все поля');
    }
  };

  useEffect(() => {
    if (authMutationError) {
      setAuthError('Неверный логин или пароль');
    }
  }, [authMutationError]);

  const navigateTo = (path: string) => () => {
    navigate(path);
  };

  return (
      <div className="page-sign-in">
        <h1>Akkauntga kirish</h1>

        <form onSubmit={onSubmit}>
          <Input
              name="phone"
              label="Telefon raqam"
              placeholder="+998 (99) 111-22-33"
              value={formFieldsValue.phone}
              onChange={onChangeHandler}
              error={!!formError || !!authError} // Если есть ошибка, то показываем
              style={{ borderColor: !!authError ? 'red' : '' }} // Устанавливаем красную рамку
          />
          {authError && <p className="form-error">{authError}</p>} {/* Отображаем ошибку */}

          <Input
              name="password"
              label="Parol"
              placeholder="******"
              type="password"
              value={formFieldsValue.password}
              onChange={onChangeHandler}
              error={!!formError || !!authError} // Если есть ошибка, то показываем
              style={{ borderColor: !!authError ? 'red' : '' }} // Устанавливаем красную рамку
          />
          {formError && <p className="form-error">{formError}</p>}

          <Button variant="underline" style={{ marginTop: 16 }} onClick={navigateTo('/auth/restore')}>
            Parolni unutdingizmi?
          </Button>

          <Button isLoading={authPending} type="submit" fullWidth size="lg" style={{ marginTop: 50 }}>
            Kirish
          </Button>

          <div className="page-sign-in__question">
            <p>
              Akkauntingiz mavjud emasmi?{' '}
              <span className="text-underline" onClick={navigateTo('/auth/sign-up')}>
              Ro'yxatdan o'tish
            </span>
            </p>
            <div>
              <hr />
              Yoki
              <hr />
            </div>
          </div>

          <OneIdButton />
        </form>
      </div>
  );
};

export default SignIn;