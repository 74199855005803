import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../index";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

interface CreateCompanyData {
    name: string;
    inn: string;
    founded: string;
    ownerId: any;
}

const createCompany = async (data: CreateCompanyData): Promise<any> => {
    const response = await axiosInstance.post('/companies', data);
    return response.data;
};

export const useCreateCompany = () => {
    const { t } = useTranslation();

    return useMutation<any, Error, CreateCompanyData>({
        mutationFn: createCompany,
        onError: (error: any) => {
                toast.error(t(error?.message || 'Error'));
        },

    });
};