import { ReactNode } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageInfo from "../../../../../components/dashboard/PageInfo"
import FlexWrapper from "../../../../../components/common/FlexWrapper";
import ContentWrapper from "../../../../../components/common/ContentWrapper";
import Button from "../../../../../components/common/Button";
import DataView from "../../../../../components/common/DataView";
import { PenIcon } from "../../../../../utils/icons";

const DriverInfo = ({ driverName, navigationSlot }: { driverName: string; navigationSlot: ReactNode }) => {
  const navigate = useNavigate();
  const { companyID } = useParams(); // Получаем companyId из параметров маршрута

  console.log(companyID)

  return (
      <div className='page-drivers-view'>
        <PageInfo backButton title={driverName} url={`/dashboard/companies/view/${companyID}/drivers`} />

        <FlexWrapper>
          {navigationSlot}

          <div className="page-content">
            <ContentWrapper>
              <div className="section-title">
                Haydovchi ma'lumotlari

                <Button variant='clear'>
                  <PenIcon />
                </Button>
              </div>

              <FlexWrapper>
                <DataView style={{ flex: 1 }} label='Haydovchi ismi' value='Ahrorbek' />
                <DataView style={{ flex: 1 }} label='Haydovchi familiyasi' value='Mirzayev' />
                <DataView style={{ flex: 1 }} label='Passport seriyasi va raqami' value='AD 234 23 53' />
              </FlexWrapper>

              <FlexWrapper>
                <DataView style={{ flex: 1 }} label='Haydovchilik guvohnomasi seriyasi va raqami' value='AD 234 23 53' />
                <DataView style={{ flex: 1 }} label='Haydovchilik guvohnomasi kategoriyasi' value='BC' />
                <DataView style={{ flex: 1 }} label='Manzil' value='Toshkent sh. Yunusobod t.  4 - mavze, 40 uy' />
              </FlexWrapper>
            </ContentWrapper>

            <ContentWrapper style={{ marginTop: 24 }}>
              <div className="section-title">
                Muvofiqliklar

                <Button variant='clear'>
                  <PenIcon />
                </Button>
              </div>

              <FlexWrapper>
                <DataView style={{ flex: 1 }} label='Mashina tegishliligi' value="O'zimniki" />
              </FlexWrapper>

              <FlexWrapper>
                <DataView style={{ flex: 1 }} label='Mashina raqami' value='60 A 927 GB' />
                <DataView style={{ flex: 1 }} label='Mashina tehnik passport raqami' value='AF 345 55 34' />
                <DataView style={{ flex: 1 }} label='Yo’nalrieilgan tirkama' value='TIR 2324' />
              </FlexWrapper>

              <FlexWrapper>
                <DataView style={{ flex: 1 }} label='Yo’naltirilgan dispetcher' value="Azizbek Mamajonov" />
              </FlexWrapper>
            </ContentWrapper>
          </div>
        </FlexWrapper>
      </div>
  )
}

export default DriverInfo;