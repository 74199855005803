import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/common/Button';
import PageInfo from '../../../components/dashboard/PageInfo';
import ContentWrapper from '../../../components/common/ContentWrapper';
import FlexWrapper from '../../../components/common/FlexWrapper';
import Input from '../../../components/common/Input';
import DragNDrop from '../../../components/common/DragNDrop';
import Combobox from '../../../components/common/Combobox';
import RadioInput from '../../../components/common/RadioInput';
import { useTranslation } from 'react-i18next';
import './styles.sass';
import { useForm } from 'react-hook-form';
import { useCreateEmployee } from "../../../api/hooks/company/employe/useCreateEmployee";
import { RefreshIcon } from "../../../utils/icons";
import {toast} from "react-toastify";
import {useCreateUser} from "../../../api/hooks/company/employe/useCreateUser";

// Function to generate a random 8-character complex password
const generatePassword = () => {
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_-+=<>?";
  let password = '';
  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
};

const DriversCreate = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const { t } = useTranslation();
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  console.log(companyId)
  const { mutateAsync: registerUser } = useCreateUser();
  const { mutateAsync: createEmployee } = useCreateEmployee();

  // Form submission handler
  const onSubmit = async (data: any) => {
    try {
      // Step 1: Create user
      const userData = {
        name: data.name,
        surename: data.surname,
        phone: data.phone,
        password: data.password,
        repeatPassword: data.password,
        roles: ["EMPLOYEE"] as [string]
      };

      const userResponse: any = await registerUser(userData);
      console.log(userResponse.id)
      const userId = userResponse.id;
      console.log(userId);
      // Step 2: Create employee with userId and companyId
      const employeeData = {
        userId,
        companyId,
        hiredDate: new Date().toISOString()
      };

      await createEmployee(employeeData);

      // Navigate back after successful creation
      navigate(-1);
    } catch (error:any) {
      toast.error(t(error?.message || 'Error'));
      console.error("Error creating driver and employee:", error);
    }
  };

  // Handle password generation when the button is clicked
  const handleGeneratePassword = () => {
    const generatedPassword = generatePassword();
    setValue('password', generatedPassword); // Set the generated password in the form
  };

  return (
      <>
        <div className='page-drivers-create'>
          <PageInfo
              title={t('add_new_driver')}
              backButton
              buttonsSlot={
                <Button onClick={handleSubmit(onSubmit)}>
                  {t('save')}
                </Button>
              }
          />

          <ContentWrapper rowGap={16}>
            <h3 className="section-title">{t('driver_info')}</h3>

            <FlexWrapper>
              <Input
                  label={t('driver_name')}
                  placeholder={t('enter_name')}
                  containerStyle={{ flex: 3 }}
                  {...register('name', { required: true })}
                  error={errors.name && t('field_required')}
              />
              <Input
                  label={t('surname')}
                  placeholder={t('enter_surname')}
                  containerStyle={{ flex: 3 }}
                  {...register('surname', { required: true })}
                  error={errors.surname && t('field_required')}
              />
              <Input
                  label={t('passport_series')}
                  placeholder={t('enter_passport_series')}
                  containerStyle={{ flex: 1 }}
                  {...register('passportSeries', { required: true })}
                  error={errors.passportSeries && t('field_required')}
              />
              <Input
                  label={t('passport_number')}
                  placeholder={t('enter_passport_number')}
                  containerStyle={{ flex: 2 }}
                  {...register('passportNumber', { required: true })}
                  error={errors.passportNumber && t('field_required')}
              />
            </FlexWrapper>

            <FlexWrapper>
              <DragNDrop containerSize='sm' label={t('front_side_passport')} />
              <DragNDrop containerSize='sm' label={t('back_side_passport')} />
            </FlexWrapper>

            <FlexWrapper>
              <Input
                  label={t('license_series')}
                  placeholder={t('enter_license_series')}
                  {...register('licenseSeries', { required: true })}
                  error={errors.licenseSeries && t('field_required')}
              />
              <Input
                  label={t('license_number')}
                  placeholder={t('enter_license_number')}
                  {...register('licenseNumber', { required: true })}
                  error={errors.licenseNumber && t('field_required')}
              />
              <Combobox label={t('license_category')} placeholder='BC' />
            </FlexWrapper>

            <h3 className="section-title">{t('address')}</h3>
            <ContentWrapper background='ltblue'>
              <FlexWrapper>
                <Combobox
                    label={t('select_province')}
                    placeholder={t('select_province')}
                    {...register('province', { required: false })}
                    error={errors.province && t('field_required')}
                />
                <Combobox
                    label={t('select_district')}
                    placeholder={t('select_district')}
                    {...register('district', { required: false })}
                    error={errors.district && t('field_required')}
                />
                <Input
                    label={t('street_name')}
                    placeholder={t('enter_street_name')}
                    {...register('street', { required: true })}
                    error={errors.street && t('field_required')}
                />
                <Input
                    label={t('zip_code')}
                    placeholder={t('zip_code')}
                    {...register('zipCode', { required: true })}
                    error={errors.zipCode && t('field_required')}
                />
              </FlexWrapper>
            </ContentWrapper>

            <h3 className="section-title">{t('compliance')}</h3>
            <FlexWrapper>
              <RadioInput name={"who"} label={t('personal')} value="personal" />
              <RadioInput name={"who"} label={t('company_owned')} value="company" />
            </FlexWrapper>

            <FlexWrapper>
              <Combobox label={t('directed_truck')} />
              <Combobox label={t('assigned_trailer')} />
              <Combobox label={t('dispatcher')} />
            </FlexWrapper>

          </ContentWrapper>
        </div>
        <div className='page-drivers-create'>
          <ContentWrapper>
            <h2 className="section-title">Akkauntga kirish</h2>
            <FlexWrapper alignItems={"flex-end"}>
              <Input
                  label="Telefon raqam"
                  placeholder="+998 (99) 111-22-33"
                  containerStyle={{ flex: 3 }}
                  {...register('phone', { required: true })}
                  error={errors.phone && t('field_required')}
              />
              <Input
                  label="Parol"
                  placeholder="******"
                  type="password"
                  containerStyle={{ flex: 3 }}
                  {...register('password', { required: true })}
                  error={errors.password && t('field_required')}
              />
              <button className={"generate_btn"} onClick={handleGeneratePassword}>
                <RefreshIcon width={"24"} height={"18"} />
              </button>
            </FlexWrapper>
          </ContentWrapper>

        </div>
      </>
  );
};

export default DriversCreate;