import axiosInstance from "../../index";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

interface Company {
    name: string;
    director: string;
    tin: string;
    driversCount: number;
    transportsCount: number;
    createdYear: number;
}

// Функция для запроса данных компаний
const fetchCompanies = async (): Promise<Company[]> => {
    const response = await axiosInstance.get('/companies');
    return response.data;
};

// Хук для получения списка компаний
const useCompanies = (): UseQueryResult<Company[], Error> => {
    return useQuery<Company[], Error>({
        queryKey: ['companies'], // Оборачиваем в объект с ключом queryKey
        queryFn: fetchCompanies,
    });
};

export default useCompanies;