import AmountPicker from "../../../../../../components/common/AmountPicker";
import Combobox from "../../../../../../components/common/Combobox";
import ContentWrapper from "../../../../../../components/common/ContentWrapper";
import DragNDrop from "../../../../../../components/common/DragNDrop";
import FlexWrapper from "../../../../../../components/common/FlexWrapper";
import Input from "../../../../../../components/common/Input";
import { CargoAmounts2 } from "../../../../../../utils/consts";
import { FC, useEffect, useState } from "react";

interface LegalEnterpriseProps {
    currentStep?: number;
    setFormData: (data: any) => void;
}

const LegalEnterprise: FC<LegalEnterpriseProps> = ({ currentStep, setFormData }) => {
    const [formData, setFormDataLocal] = useState<any>({});
    const [selectedTrucks, setSelectedTrucks] = useState<string>("1-5");
    const [selectedEmployees, setSelectedEmployees] = useState<string>("6-10");

    // Update formData when local state changes
    useEffect(() => {
        setFormData(formData);
    }, [formData, setFormData]);


    return (
        <>
            {currentStep === 1 && (
                <div>
                    <p className="block-title">Korxona tarkibi</p>
                    <FlexWrapper flexWrap="wrap" style={{ marginTop: 24 }}>
                        <AmountPicker
                            gap={8}
                            label="Nechta yuk mashinangiz bor?"
                            amounts={CargoAmounts2}
                            selectedAmount={selectedTrucks}
                            onAmountClick={(amount) => {
                                setSelectedTrucks(amount);
                                setFormDataLocal({ ...formData, trucks: amount });
                            }}
                        />
                        <AmountPicker
                            gap={8}
                            label="Nechta ishchingiz bor?"
                            amounts={CargoAmounts2}
                            selectedAmount={selectedEmployees}
                            onAmountClick={(amount) => {
                                setSelectedEmployees(amount);
                                setFormDataLocal({ ...formData, employer: amount });
                            }}
                        />
                    </FlexWrapper>
                </div>
            )}

            {currentStep === 2 && (
                <div>
                    <p className="block-title">Korxona ma’lumotlari</p>
                    <FlexWrapper style={{ marginTop: 24 }}>
                        <Input
                            label="Korxona nomi"
                            placeholder="Korxona nomini kiriting"
                            onChange={(e) => setFormDataLocal({ ...formData, companyName: e.target.value })}
                        />
                        <Input
                            label="INN"
                            placeholder="INN kiriting"
                            onChange={(e) => setFormDataLocal({ ...formData, inn: e.target.value })}
                        />
                    </FlexWrapper>
                    <FlexWrapper gap={24} style={{ marginTop: 24 }}>
                        <DragNDrop
                            containerSize="sm"
                            label="Guvohnomasi fayli"
                            fileTypeDescr="Format .pdf, .jpg or.png yuklang"
                            onChange={(file) => setFormDataLocal({ ...formData, certificateFile: file })}
                        />
                        <DragNDrop
                            containerSize="sm"
                            label="Rahbar passporti (oldi)"
                            fileTypeDescr="Format .pdf, .jpg or.png yuklang"
                            onChange={(file) => setFormDataLocal({ ...formData, passportFrontFile: file })}
                        />
                        <DragNDrop
                            containerSize="sm"
                            label="Rahbar passporti (orqa)"
                            fileTypeDescr="Format .pdf, .jpg or.png yuklang"
                            onChange={(file) => setFormDataLocal({ ...formData, passportBackFile: file })}
                        />
                    </FlexWrapper>
                </div>
            )}

            {currentStep === 3 && (
                <>
                    <p className="block-title">Rekvizit ma’lumotlari</p>
                    <ContentWrapper>
                        <FlexWrapper>
                            <Input
                                label="Bank nomi"
                                placeholder="Bank nomini kiriting"
                                onChange={(e) => setFormDataLocal({ ...formData, bankName: e.target.value })}
                            />
                            <Input
                                label="MFO"
                                placeholder="MFO raqami"
                                onChange={(e) => setFormDataLocal({ ...formData, mfo: e.target.value })}
                            />
                        </FlexWrapper>

                        <FlexWrapper>
                            <Input
                                label="Hisob raqam"
                                placeholder="Hisob raqaningizni kiriting"
                                onChange={(e) => setFormDataLocal({ ...formData, accountNumber: e.target.value })}
                            />
                            <Combobox
                                label="Valyuta"
                                defaultValue="uzs"
                                options={[
                                    { label: "UZS", value: "UZS" },
                                    { label: "USD", value: "USD" },
                                    { label: "RUB", value: "RUB" },
                                ]}
                                onSelect={(value: any) => setFormDataLocal({ ...formData, currency: value })}
                            />
                        </FlexWrapper>
                    </ContentWrapper>
                </>
            )}
        </>
    );
};

export default LegalEnterprise;