import { useState } from 'react'
import RoleCard from '../../../components/authorization/RoleCard'
import FlexWrapper from '../../../components/common/FlexWrapper'
import ProgressBar from '../../../components/common/ProgressBar'
import Button from '../../../components/common/Button'
import LogisticianTab from './RolesTabs/LogisticianTab'
import TruckDeliveryTab from './RolesTabs/TruckDeliveryTab'
import CargoOwnerTab from './RolesTabs/CargoOwnerTab'
import { RoleCards } from '../../../utils/consts'
import { ROLES } from '../../../types/roles.types'
import './styles.sass'
import {useUpdateUser} from "../../../api/hooks/ useUpdateUser";

const Addtional = () => {
    const [selectedRole, setSelectedRole] = useState<ROLES | null>(null);
    const [selectedAmount, setSelectedAmount] = useState('');
    const [currentStep, setCurrentStep] = useState(1); // Начальный шаг

    const { mutate: userUpdate } = useUpdateUser(); // Initialize the update user mutation

    const handleRoleClick = (role: ROLES) => {
        setSelectedRole(role);

        // Call userUpdate when a role is selected
        userUpdate({
            roles: [role] // Pass the selected role to update user's roles
        });

        // Set the current step based on the selected role
        if (role === ROLES.LOGISTICIAN) {
            setCurrentStep(2);
        } else if (role === ROLES.TRUCK_DELIVERY) {
            setCurrentStep(3);
        } else if (role === ROLES.CARGO_OWNER) {
            setCurrentStep(4);
        }
    };

    return (
        <div className='page-additional'>
            <h1 className="page-additional__title">Qo'shimcha ma'lumotlar</h1>

            <ProgressBar currentStep={currentStep} style={{ marginTop: 24 }} />

            <form className='form'>
                <h4 className="form__title">
                    Rolingizni tanlang
                </h4>

                <FlexWrapper style={{ marginTop: 16 }}>
                    {RoleCards.map(({ iconUrl, title, role }) => (
                        <RoleCard
                            key={role}
                            iconUrl={iconUrl}
                            title={title}
                            isActive={selectedRole === role}
                            onClick={() => handleRoleClick(role)}
                        />
                    ))}
                </FlexWrapper>

                <div className="tabs-wrapper">
                    {selectedRole === ROLES.LOGISTICIAN && (
                        <LogisticianTab />
                    )}
                    {selectedRole === ROLES.TRUCK_DELIVERY && (
                        <TruckDeliveryTab />
                    )}
                    {selectedRole === ROLES.CARGO_OWNER && (
                        <CargoOwnerTab selectedAmount={selectedAmount} setSelectedAmount={setSelectedAmount} />
                    )}
                </div>
            </form>

        </div>
    )
}

export default Addtional