import { useNavigate, useParams } from "react-router-dom";
import PageInfo from "../../../components/dashboard/PageInfo";
import Button from "../../../components/common/Button";
import ContentWrapper from "../../../components/common/ContentWrapper";
import FlexWrapper from "../../../components/common/FlexWrapper";
import Input from "../../../components/common/Input";
import Combobox from "../../../components/common/Combobox";
import RadioInput from "../../../components/common/RadioInput";
import './styles.sass';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import {useCreateTrailer} from "../../../api/hooks/company/trailers/useCreateTrailer";
import {TrailersStatus} from "../../../utils/consts";

const TrailersCreate = () => {
  const navigate = useNavigate();
  const { companyID } = useParams();
  const { t } = useTranslation();
  const [yearOptions, setYearOptions] = useState<any[]>([]);
  const [carYear, setCarYear] = useState<any>([]);
  const [trailerStatus, setTrailerStatus] = useState<any>([]);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  const { mutateAsync: registerTrailer } = useCreateTrailer();

  useEffect(() => {
    const generateYears = () => {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let year = currentYear; year >= 1990; year--) {
        years.push({ label: year, value: year });
      }
      return years;
    };
    setYearOptions(generateYears());
  }, []);

  const onSubmit = async (data: any) => {
    try {
      const trailerData = {
        name: data.name,
        model: data.model,
        serial: data.serial,
        number: data.number,
        status: trailerStatus ,
        companyId: companyID,
        year: carYear.toString(),
        type: "RAILCAR",
      };

      await registerTrailer({
        ...trailerData,
      });

      navigate(-1);  // Go back to the previous page after success
    } catch (error: any) {
      toast.error(t(error?.message || 'Error'));
      console.error("Error creating trailer:", error);
    }
  };

  return (
      <div className='page-trailers-create'>
        <PageInfo
            title={t('TrailersCreateTitle')}
            backButton
            buttonsSlot={
              <Button onClick={handleSubmit(onSubmit)}>
                {t('saveButton')}
              </Button>
            }
        />

        <ContentWrapper rowGap={16}>
          <h3 className="section-title">{t('sectionTitleTrailerInfo')}</h3>

          <FlexWrapper>
            <Input
                label={t('trailerNameLabel')}
                placeholder={t('trailerNamePlaceholder')}
                {...register('name', { required: t('trailerNameError') })}
                error={errors.name ? errors.name.message : ''}
            />
            <Input
                label={t('trailerModelLabel')}
                placeholder={t('trailerModelPlaceholder')}
                {...register('model', { required: t('trailerModelError') })}
                error={errors.model ? errors.model.message : ''}
            />
            <Combobox
                label={t('yearOfManufactureLabel')}
                options={yearOptions}
                onSelect={(value: any) => { setCarYear(value); }}
                placeholder={t('yearOfManufacturePlaceholder')}
            />
          </FlexWrapper>

          <FlexWrapper>
            <Input
                label={t('trailerSerialLabel')}
                placeholder={t('trailerSerialPlaceholder')}
                {...register('serial', { required: t('trailerSerialError') })}
                error={errors.serial ? errors.serial.message : ''}
            />

            <Combobox
                label={t('status')}
                options={TrailersStatus}
                onSelect={(value: any) => {setTrailerStatus(value)}}
                placeholder={t('status')}
            />
            <Input
                label={t('trailerNumberLabel')}
                placeholder={t('trailerNumberPlaceholder')}
                {...register('number', { required: t('trailerNumberError') })}
                error={errors.number ? errors.number.message : ''}
            />
          </FlexWrapper>
        </ContentWrapper>


      </div>
  );
};

export default TrailersCreate;