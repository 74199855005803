import { useState } from 'react';
import LegalEnterprise from './LegalEnterprise';
import Individual from './Individual';
import Button from "../../../../../components/common/Button";
import './styles.sass';
import Combobox from "../../../../../components/common/Combobox";
import { getUserIdFromToken } from "../../../../../utils/authUtils";
import { useCreateCompany } from "../../../../../api/hooks/company/useCreateCompany";
import { useCreateRequisites } from "../../../../../api/hooks/company/useCreateRequisites";
import {useNavigate} from "react-router-dom";

type DeliveryType = 'individual' | 'enterprise';

const TruckDeliveryTab = () => {
    const [deliveryType, setDeliveryType] = useState<DeliveryType>('enterprise');
    const [currentStep, setCurrentStep] = useState<number>(1);
    const totalSteps = 3;
    const userId = getUserIdFromToken();

    const [individualData, setIndividualData] = useState<any>({});
    const [enterpriseData, setEnterpriseData] = useState<any>({});
    const [heardFrom, setHeardFrom] = useState<number | string>("");
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const { mutate: createCompany } = useCreateCompany();
    const { mutate: createRequisites } = useCreateRequisites();

    const navigate = useNavigate();
    const [companyId , setCompanyId] = useState<string>("");
    const handleNextStep = () => {
        if (currentStep === 2 && deliveryType === 'enterprise') {
            const data = {
                name: enterpriseData.companyName,
                inn: enterpriseData.inn,
                founded: enterpriseData.founded || "2024",
                employeeCount: enterpriseData.employer,
                transportCount : enterpriseData.trucks,
                ownerId: userId
            };

            // Попытка создать компанию и обработка ошибок
            createCompany(data, {
                onSuccess: (data) =>  {
                    setCompanyId(data.id)
                    setCurrentStep(currentStep + 1)
                },
                onError: (error: any) => {
                    setCurrentStep((prevState) => prevState - 1); // Вернуться на шаг 1 в случае ошибки
                }
            });
        } else if (currentStep < totalSteps) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handleSubmit = () => {
        const data = deliveryType === 'individual' ? individualData : enterpriseData;

        if (userId) {
            if (currentStep === 3 && deliveryType === 'enterprise') {
                const requisitesData = {
                    bank: enterpriseData.bankName,
                    accountNumber: enterpriseData.accountNumber,
                    mfo: enterpriseData.mfo,
                    currency: enterpriseData.currency,
                    companyId: companyId
                };

                createRequisites(requisitesData, {
                    onSuccess:() => navigate('/dashboard/companies'),
                    onError: (error: any) => {
                        setErrorMessage( error?.response?.data?.message?.message[0] || 'Ошибка при создании реквизитов');
                        setCurrentStep(3);
                    }
                });
            }

            console.log('Submitting data:', data);
        } else {
            console.error("No user ID found in the token");
        }
    };

    const changeDeliveryType = (type: DeliveryType) => {
        setDeliveryType(type);
        setCurrentStep(1);
    };

    return (
        <div>
            <div className='truck-delivery-tab'>
                <div className="tabs-header">
                    <button
                        type="button"
                        className={`tab-button ${deliveryType === 'enterprise' ? 'active' : ''}`}
                        onClick={() => changeDeliveryType('enterprise')}
                    >
                        Yuridik firma
                    </button>
                    <button
                        type="button"
                        className={`tab-button ${deliveryType === 'individual' ? 'active' : ''}`}
                        onClick={() => changeDeliveryType('individual')}
                    >
                        Jismoniy shaxs
                    </button>
                </div>
                <div className="form-body">
                    {deliveryType === 'individual' ? (
                        <Individual currentStep={currentStep} setFormData={setIndividualData} />
                    ) : (
                        <LegalEnterprise currentStep={currentStep} setFormData={setEnterpriseData} />
                    )}
                </div>
            </div>
            <br />
            <div>
                {currentStep === totalSteps ? (
                    <Combobox
                        label='Biz haqimizda qayerdan eshitdingiz?'
                        options={[
                            { label: 'Do\'stim taklif qildi', value: 0 },
                            { label: 'Ijtimoiy tarmoqlardan', value: 1 },
                            { label: 'Websayt sahifalaridan', value: 2 }
                        ]}
                        onSelect={(label) => {
                            setHeardFrom(label); // Сохраняем значение, выбранное пользователем
                            console.log(label);
                        }}
                    />
                ) : null}
            </div>

            <div className="form-navigation">
                {currentStep === totalSteps ? (
                    <Button fullWidth size='lg' style={{ marginTop: 24 }} type="button" onClick={handleSubmit}>
                        Yuborish
                    </Button>
                ) : (
                    <Button fullWidth size='lg' style={{ marginTop: 24 }} type="button" onClick={handleNextStep}>
                        Keyingisi
                    </Button>
                )}
            </div>
        </div>
    );
};

export default TruckDeliveryTab;