// api/auth/mutation/login.tsx

import {useCustomMutation} from "../../hooks/useCustomMutation";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";

export interface LoginData {
    phone: string;
    password: string;
    accessToken : string
}

export const useLogin = () => {
const navigate = useNavigate();
    return useCustomMutation<LoginData, LoginData>('/auth/sign-in', {
        onSuccess: (data) => {
            console.log('Login successful', data);
            Cookies.set('accessToken', data.accessToken, { expires: 30, secure: true, sameSite: 'Strict' });
            navigate('/dashboard')
        },

        onError: (error) => {
            console.error('Login failed', error);
        },
    });
};