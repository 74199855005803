import { useNavigate } from "react-router-dom";
import Button from "../../../components/common/Button";
import PageInfo from "../../../components/dashboard/PageInfo";
import DataTable from "../../../components/dashboard/DataTable";
import FlexWrapper from "../../../components/common/FlexWrapper";
import { PlusIcon, PenIcon, TrashIcon } from "../../../utils/icons";
import { Column } from "../../../types/table.types";
import './styles.sass';
import useCompanies from "../../../api/hooks/company/useCompanies";
import { useTranslation } from "react-i18next"; // Import useTranslation

interface CompaniesRow {
  name: string;
  director: string;
  tin: string;
  employeeCount: string;
  transportCount: string;
  founded: string;
}

const CompaniesTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(); // Use the hook

  const { data: companies, isLoading, error } = useCompanies(); // Use custom hook for data

  const columns: Column[] = [
    {
      id: 'companiesName',
      headerName: t('companyName'), // Use translation key
      flex: 1,
      renderCell(row) {
        return (
            <div
                onClick={() => navigate(`view/${row.id}/info`)} // Correct URL structure
                style={{cursor: "pointer", textDecoration: "underline", color: "#3B7CCC"}}
            >
              {row.name}
            </div>
        );
      },
    },
    {
      id: 'director',
      headerName: t('companyDirector'), // Use translation key
      flex: 2,
      renderCell(row) {
        return <div>{row.owner?.name}</div>;
      },
    },
    {
      id: 'tin',
      headerName: t('tin'), // Use translation key
      flex: 1,
      renderCell(row) {
        return <div>{row.inn}</div>;
      },
    },
    {
      id: 'driver-count',
      headerName: t('driverCount'), // Use translation key
      flex: 1,
      renderCell(row: CompaniesRow) {
        return <div>{row?.employeeCount}</div>;
      },
    },
    {
      id: 'transport-count',
      headerName: t('transportCount'), // Use translation key
      flex: 1,
      renderCell(row: CompaniesRow) {
        return <div>{row.transportCount}</div>;
      },
    },
    {
      id: 'created-year',
      headerName: t('yearFounded'), // Use translation key
      flex: 1,
      renderCell(row: CompaniesRow) {
        return <div>{row?.founded}</div>;
      },
    },
    {
      id: 'action',
      headerName: '',
      flex: 0.5,
      renderCell(row: CompaniesRow) {
        return (
            <FlexWrapper>
              <Button variant="clear">
                <PenIcon />
              </Button>
              <Button variant="clear">
                <TrashIcon />
              </Button>
            </FlexWrapper>
        );
      },
    },
  ];

  if (isLoading) {
    return <div>{t('loading')}</div>; // Use translation key
  }

  if (error) {
    return <div>{t('errorLoadingCompanies')}</div>; // Use translation key
  }

  return (
      <div className="page-companies-table">
        <PageInfo
            title={t('myCompanies')} // Use translation key
            buttonsSlot={
              <Button onClick={() => navigate('create')} iconLeft={<PlusIcon width={20} height={20} stroke="#fff" />}>
                {t('createNewCompany')} {/* Use translation key */}
              </Button>
            }
        />
        <DataTable rows={companies || []} columns={columns} />
      </div>
  );
};

export default CompaniesTable;