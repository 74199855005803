import { ChangeEvent, FormEvent, useState } from 'react';
import Input from '../../../components/common/Input';
import Button from '../../../components/common/Button';
import ProgressBar from "../../../components/common/ProgressBar";
import { resetPassword } from "../../../api/auth/queries/resetPassword"; // мутация для сброса пароля
import {useLocation, useNavigate} from 'react-router-dom';
import './styles.sass';

const NewPassword = () => {
  const location = useLocation();
  const { phoneNumber, otp } = location.state || {};
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [formError, setFormError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setFormError('Passwords do not match');
      return;
    }

    try {
      setLoading(true);
      await resetPassword(phoneNumber, otp, newPassword); // вызываем API с номером, OTP и новым паролем
      console.log('Password reset successful');
      // Редирект на успешную страницу или главную
      navigate('/auth')
    } catch (error) {
      setFormError('Parolni tiklashda xatolik yuz berdi , OTP ni tekshiring');
      console.error('Password reset failed:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
      <div className='page-new-password'>
        <h1 className='page-new-password__title'>Yangi parol yarating</h1>
        <ProgressBar stepsCount={3} currentStep={3} style={{ marginTop: 24 }} />
        <form onSubmit={onSubmit}>
          <Input
              label='Yangi parol'
              type='password'
              value={newPassword}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
          />
          <Input
              label='Qayta kiriting'
              type='password'
              value={confirmNewPassword}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setConfirmNewPassword(e.target.value)}
          />
          {formError && <p className='form-error'>{formError}</p>}
          <Button type='submit' fullWidth size='lg' style={{ marginTop: 50 }} disabled={loading}>
            {loading ? 'Yuborilmoqda...' : 'Saqlash'}
          </Button>
        </form>
      </div>
  );
};

export default NewPassword;