import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from "react-hook-form";
import Button from '../../../components/common/Button';
import FlexWrapper from '../../../components/common/FlexWrapper';
import Input from '../../../components/common/Input';
import ProgressBar from '../../../components/common/ProgressBar';
import MaskedInput from "../../../components/common/MaskedInput";
import './styles.sass';
import {useRegister} from "../../../api/auth/mutation/regis";
import Cookies from 'js-cookie';
import logo from "../../../components/common/Logo";

interface IFormInputs {
  name: string;
  surename: string;
  phone: string;
  password: string;
  repeatPassword: string;
}

const SignUp = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { register, formState: { errors }, handleSubmit, control, watch } = useForm<IFormInputs>();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const password = watch('password');

  const { mutateAsync: registerUser, isLoading, isError, error } = useRegister() as any; // Используем мутацию для регистрации

  useEffect(() => {
    const checkPasswordStrength = (password: string) => {
      const conditions = [
        /.{8,}/,
        /(?=.*[a-z])(?=.*[A-Z])/,
        /[0-9]/,
        /[!@#$%^&*(),.?":{}|<>]/,
      ];

      const passedConditions = conditions.reduce((acc, condition) => acc + (condition.test(password) ? 1 : 0), 0);
      setPasswordStrength(passedConditions);
    };

    checkPasswordStrength(password);
  }, [password]);

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    try {
      if (!data.phone.startsWith('+')) {
        data.phone = `+${data.phone}`;
      }

      const response = await registerUser(data); // Регистрируем пользователя и получаем ответ

      // Доступ к токенам из ответа
      const accessToken = response?.accessToken; // Получаем accessToken
      const refreshToken = response?.refreshToken?.token; // Получаем refreshToken из объекта

      if (accessToken) {
        Cookies.set('accessToken', accessToken, { expires: 30, secure: true, sameSite: 'Strict' });
      }

      if (refreshToken) {
        Cookies.set('refresh_token', refreshToken, { expires: 30, secure: true, sameSite: 'Strict' });
      }

      navigate('/auth/additional-info');
    } catch (err) {
      console.error(err);
    }
  };

  return (
      <div className='page-sign-up'>
        <h1 className="page-sign-up__title">
          Ro'yxatdan o'tish
        </h1>

        <ProgressBar currentStep={1} style={{ marginTop: 24 }} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexWrapper>
            <Input
                label='Ism'
                required
                {...register('name', {
                  required: "Please enter your first name",
                  minLength: {
                    value: 4,
                    message: "Minimum length is 4 characters"
                  },
                  validate: {
                    noSpaces: (value) => value.trim() !== "" || "Field cannot be just spaces"
                  }
                })}
                error={errors.name?.message}
            />
            <Input
                label='Familiya'
                {...register('surename', {
                  required: "Please enter your surname",
                  minLength: {
                    value: 4,
                    message: "Minimum length is 4 characters"
                  },
                  validate: {
                    noSpaces: (value) => value.trim() !== "" || "Field cannot be just spaces"
                  }
                })}
                error={errors.surename?.message}
                required
            />
          </FlexWrapper>
          <MaskedInput
              label="Telefon raqam"
              name="phone"
              control={control}
              mask="+{998} (00) 000 00 00"
              unmask
              placeholder='+998 (00) 000 00 00'
              required
              rules={{
                required: t('Please enter your phone number.'),
                pattern: {
                  value: /^[0-9]+$/,
                  message: t('Phone number should only contain numbers.'),
                },
                validate: {
                  exactLength: (value = '') => (value.length === 12) || t('Phone number must be exactly 12 digits.'),
                },
              }}
          />
          <Input
              label='Parol'
              type='password'
              {...register('password', {
                required: "Please enter password",
                minLength: {
                  value: 8,
                  message: "Minimum length is 8 characters"
                },
                validate: {
                  noSpaces: (value) => value.trim() !== "" || "Field cannot be just spaces"
                }
              })}
              error={errors.password?.message}
              required
              placeholder='parolni kiriting'
          />
          <Input
              label='Parolni qayta kiriting'
              type='password'
              {...register('repeatPassword', {
                required: "Please confirm the password",
                validate: {
                  matchesPassword: (value) => value === password || "Passwords do not match",
                },
              })}
              error={errors.repeatPassword?.message}
              required
              placeholder='parolni kiriting'
          />

          <div className="password-strength">
            <p className="password-strength__descr">
              Kalit so'z mustahkamligi
            </p>
            <ProgressBar
                style={{ marginTop: 12 }}
                currentStep={passwordStrength}
                stepsCount={4}
                size='sm'
                coloredProgress
            />

            <h4 className='password-strength__title'>
              Parolingizda quyidagilar bo'lishi kerak:
            </h4>
            <ul className='password-strength__list'>
              <li className='password-strength__list-item'>
                Kamida 8 ta belgi
              </li>

              <li className='password-strength__list-item'>
                Katta va kichik harflar
              </li>

              <li className='password-strength__list-item'>
                Raqam
              </li>

              <li className='password-strength__list-item'>
                Maxsus belgi (%, $, # va boshqalar)
              </li>
            </ul>
          </div>

          {isError && <p style={{ color: 'red' }}>{error?.status == 409 ? "Пользователь уже есть" : 'Регистрация не удалась. Попробуйте снова.'}</p>} {/* Отображаем ошибку регистрации */}

          <Button
              size='lg'
              type='submit'
              style={{ marginTop: 16 }}
              fullWidth
              disabled={isLoading} // Отключаем кнопку, пока идет загрузка
          >
            {isLoading ? 'Загрузка...' : 'Ro\'yxatdan o\'tish'}
          </Button>
        </form>
      </div>
  );
};

export default SignUp;