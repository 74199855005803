import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import axiosInstance from "../../../index";
import { useTranslation } from "react-i18next";

interface TruckData {
    name: string;
    model: string;
    year: string;
    serial: string;
    number: string;
    status: string;
    companyId: string | undefined;
    trailer?: string;
    driver?: string;
    driverAddress?: string;
    note?: string;
    type?: string;
}

const createTrailerMutationFn = (truckData: TruckData) =>
    axiosInstance.post('/transport', truckData);

export const useCreateTrailer = () => {
    const { t } = useTranslation();

    return useMutation<any, Error, TruckData>({
        mutationFn: createTrailerMutationFn,
        onError: (error: any) => {
            toast.error(t(error?.message || 'Error'));
        },
        onSuccess: () => {
            toast.success(t('truckCreatedSuccess'));
        }
    });
};