// api/auth/mutation/register.tsx

import {useCustomMutation} from "../../hooks/useCustomMutation";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

export interface RegisterData {
    name : string;
    surename : string;
    phone : string;
    password: string;
    repeatPassword: string;
    roles?: [string]
}

export const useRegister = () => {
    const {t} = useTranslation();
    return useCustomMutation<RegisterData, RegisterData>('/auth/sign-up', {

        onError: (error:any) => {
            toast.error(t(error?.message || 'Error'));
            console.error('Registration failed', error);
        },
    });
};

