import { Navigate } from "react-router-dom"
import CompaniesCreatePage from "../pages/Companies/Create"
import CompaniesTablePage from "../pages/Companies/Table"
import CompaniesViewPage from "../pages/Companies/View"
import DriversCreate from "../pages/Drivers/Create"
import DriversView from "../pages/Drivers/View"
import Maps from "../pages/Maps"
import NotFound from "../pages/NotFound/NotFound"
import TrucksCreate from "../pages/Trucks/Create";
import TrucksView from "../pages/Trucks/View";
import TrailersCreate from "../pages/Trailers/Create";
import TrailersView from "../pages/Trailers/View";
import DriversEdit from "../pages/Drivers/Edit";

const dashboardRoutes = [
	{
		index: true,
		path: '',
		element: <Navigate to="companies" />,
	},
	{
		path: 'companies',
		children: [
			{
				index: true,
				element: <CompaniesTablePage />,
			},
			{
				path: 'create',
				element: <CompaniesCreatePage />,
			},
			{
				path: 'view/:companyID/*',
				element: <CompaniesViewPage />,
			},
		],
	},
	{
		path: 'drivers',
		children: [
			{
				path: ':companyID/create',
				element: <DriversCreate />,
			},
			{
				path: ':companyID/view/*',
				element: <DriversView />,
			},
			{
				path: ':userID/edit/*',
				element: <DriversEdit />,
			},
		],
	},
	{
		path: 'maps/*',
		element: <Maps />,
	},
	{
		path: 'trucks',
		children: [
			{
				path: ':companyID/create',
				element: <TrucksCreate />,
			},
			{
				path: 'view',
				element: <TrucksView />,
			},
		],
	},
	{
		path: 'trailers',
		children: [
			{
				path: ':companyID/create',
				element: <TrailersCreate />,
			},
			{
				path: 'view',
				element: <TrailersView />,
			},
		],
	},
	{
		path: '*',
		element: <NotFound />,
	},
];


export default dashboardRoutes
