import axios from 'axios';
import Cookies from "js-cookie";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://api.dev.yuk.uz/api',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Interceptor to dynamically set the Authorization header
axiosInstance.interceptors.request.use((config) => {
  const token = Cookies.get('accessToken'); // Retrieve the latest token
  if (token) {
    config.headers.Authorization = `${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axiosInstance;